<template>
<div v-for="item in tuiguang" :key="item" >


  <div class="fixe" v-for="list in item.fixe" :key="list">
     <img :src="list.fixe_1" />
      <p>{{ list.fixe_2 }}<br/>{{ list.fixe_3 }}</p>
  </div>


      <div class="banner wfl" v-for="list in item.banner" :key="list">
            <div class="bb wfl">
               <img :src="list.banner1" />
               <img :src="list.banner2" />
               <img :src="list.banner3" />
               <img :src="list.banner4" />
               <img :src="list.banner5" />
               <img :src="list.banner6" />
               <img :src="list.banner7" />
               <img :src="list.banner8" />
            </div>
            <div class="icon">
               <img :src="list.banner9" />
               <img :src="list.banner10" />
               <img :src="list.banner11" />
               <img :src="list.banner12" />
               <img :src="list.banner13" class="a5"/>
            </div>
            <div class="banner_wap wfl">
               <img :src="list.banner14" />
               <img :src="list.banner15" />
               <img :src="list.banner16" />
               <img :src="list.banner17" />
               <img :src="list.banner18" />
               <img :src="list.banner19" />
               <img :src="list.banner20" />
               <img :src="list.banner21" />
               <img :src="list.banner22" />
               <img :src="list.banner23" />
           </div>
      </div>
      <div class="block_1167" v-for="list in item.block_1167" :key="list">
            <div class="m1 wfl">
               <h1> <img :src="list.block_1167_1" /></h1>
               <span>
              <img :src="list.block_1167_2" class="web"/>
              <img :src="list.block_1167_3" class="web"/>
              <img :src="list.block_1167_4" class="web"/>
              <img :src="list.block_1167_5" class="web"/>
              <img :src="list.block_1167_6" class="web"/>
              <img :src="list.block_1167_7" class="web"/>
              <img :src="list.block_1167_8" class="web"/>
              <img :src="list.block_1167_9" class="web"/>
              <img :src="list.block_1167_10" class="web"/>
              <img :src="list.block_1167_11" class="web"/>
              <img :src="list.block_1167_12" class="wap" />
              <img :src="list.block_1167_13" class="wap" />
              <img :src="list.block_1167_14" class="wap" />
              <img :src="list.block_1167_15" class="wap" />
              <img :src="list.block_1167_16" class="wap" />
              <img :src="list.block_1167_17" class="wap" />
              <img :src="list.block_1167_18" class="wap" />
              <img :src="list.block_1167_19" class="wap" />
              <img :src="list.block_1167_20" class="wap" />
              <img :src="list.block_1167_21" class="wap" />
              <img :src="list.block_1167_22" class="wap" />
              <img :src="list.block_1167_23" class="wap" />
              <img :src="list.block_1167_24" class="wap" />
              <img :src="list.block_1167_25" class="wap" />
              <img :src="list.block_1167_26" class="wap" />
              <img :src="list.block_1167_27" class="wap" />
              <img :src="list.block_1167_28" class="wap" />
              <img :src="list.block_1167_29" class="wap" />
              <img :src="list.block_1167_30" class="wap" />
              <img :src="list.block_1167_31" class="wap" />
              </span>
           </div>
      </div>
      <div class="block_946"   v-for="list in item.block_946" :key="list">
           <div class="sb_jiaoshou_block">
               <div class="jiaoshou">
                   <div class="j_left">
                       <p><b> {{ list.block_946_1 }}</b>{{ list.block_946_2 }}</p>
                   </div>
                   <div class="j_right">
                        <img :src="list.block_946_3"  />
                        <i>{{ list.block_946_4 }}</i>
                    </div>
                    <div class="zhegnshu wfl">
                      <img :src="list.block_946_5"  />
                      <img :src="list.block_946_6"  />
                      <img :src="list.block_946_7"  />
                      <img :src="list.block_946_8"  />
                      <img :src="list.block_946_9"  />
                    </div>
                    <div class="zhegnshu_wap wfl">
                      <img :src="list.block_946_10"  />
                      <img :src="list.block_946_11"  />
                      <img :src="list.block_946_12"  />
                      <img :src="list.block_946_13"  />
                      <img :src="list.block_946_14"  />
                    </div>
                    <div class="link">
                        <a :href="'tel:' + list.block_946_15"> <img :src="list.block_946_16"  /></a>
                         <a :href="list.block_946_17"> <img :src="list.block_946_18"  class="dd"/></a>
                    </div>
             </div>
             <div class="bar wfl">
                    <h2><img :src="list.block_946_19" /></h2>
             </div>
          </div>
      </div>
      <div class="block_1167" v-for="list in item.block_1168" :key="list">  
          <div class="m2 wfl">
                <div class="m2_title">
                      <div class="tt">
                         <i>{{ list.block_1168_1 }}</i>
                         <p>{{ list.block_1168_2 }}</p>
                </div>
          </div>
          <span>
                 <img :src="list.block_1168_3" class="web"/>
                 <img :src="list.block_1168_4" class="web"/>
                 <img :src="list.block_1168_5" class="web"/>
                 <img :src="list.block_1168_6" class="web"/>
                 <img :src="list.block_1168_7" class="web"/>
                 <img :src="list.block_1168_8" class="web"/>
                 <img :src="list.block_1168_9" class="web"/> 
                 <img :src="list.block_1168_10" class="web"/>
                 <img :src="list.block_1168_11" class="web"/>
                 <img :src="list.block_1168_12" class="web"/>
                 <img :src="list.block_1168_13" class="wap"/>
                 <img :src="list.block_1168_14" class="wap"/>
                 <img :src="list.block_1168_15" class="wap"/>
                 <img :src="list.block_1168_16" class="wap"/>
                 <img :src="list.block_1168_17" class="wap"/>
                 <img :src="list.block_1168_18" class="wap"/>
                 <img :src="list.block_1168_19" class="wap"/>
                 <img :src="list.block_1168_20" class="wap"/>
                 <img :src="list.block_1168_21" class="wap"/>
                 <img :src="list.block_1168_22" class="wap"/>
                 <img :src="list.block_1168_23" class="wap"/>
                 <img :src="list.block_1168_24" class="wap"/>
                 <img :src="list.block_1168_25" class="wap"/>
                 <img :src="list.block_1168_26" class="wap"/>
                 <img :src="list.block_1168_27" class="wap"/>
                 <img :src="list.block_1168_28" class="wap"/>
                 <img :src="list.block_1168_29" class="wap"/>
                 <img :src="list.block_1168_30" class="wap"/>
                 <img :src="list.block_1168_31" class="wap"/>
                 <img :src="list.block_1168_32" class="wap"/>
            </span>
            </div>
       </div>

          <div class="block_946" v-for="list in item.block_947" :key="list">
               <div class="sb_jiaoshou_block2">
                    <div class="tewngtong">
                         <div class="mm_title">
                              <i>{{ list.block_947_1 }}</i>
                              <p>{{ list.block_947_2 }}</p>
                         </div>
                    <div class="m_img wfl">
                       <img :src="list.block_947_3" class="web"/>
                       <img :src="list.block_947_4" class="web"/>
                       <img :src="list.block_947_5" class="web"/>
                       <img :src="list.block_947_6" class="web"/>
                       <img :src="list.block_947_7" class="web"/>
                       <img :src="list.block_947_8" class="web"/>
                       <img :src="list.block_947_9" class="web"/>
                       <img :src="list.block_947_10" class="web"/>
                       <img :src="list.block_947_11" class="web"/>
                       <img :src="list.block_947_12" class="web"/>
                       <img :src="list.block_947_13" class="wap"/>
                       <img :src="list.block_947_14" class="wap"/>
                       <img :src="list.block_947_15" class="wap"/>
                       <img :src="list.block_947_16" class="wap"/>
                       <img :src="list.block_947_17" class="wap"/>
                       <img :src="list.block_947_18" class="wap"/>
                       <img :src="list.block_947_19" class="wap"/>
                       <img :src="list.block_947_20" class="wap"/>
                       <img :src="list.block_947_21" class="wap"/>
                       <img :src="list.block_947_22" class="wap"/>
                       <img :src="list.block_947_23" class="wap"/>
                       <img :src="list.block_947_24" class="wap"/>
                       <img :src="list.block_947_25" class="wap"/>
                       <img :src="list.block_947_26" class="wap"/>
                       <img :src="list.block_947_27" class="wap"/>
                       <img :src="list.block_947_28" class="wap"/>
                       <img :src="list.block_947_29" class="wap"/>
                       <img :src="list.block_947_30" class="wap"/>
                       <img :src="list.block_947_31" class="wap"/>
                       <img :src="list.block_947_32" class="wap"/>
                   </div>
                </div>
                <div class="link2">
                       <a :href="'tel:' + list.block_947_33"> <img :src="list.block_947_34"  /></a>
                       <a :href="list.block_947_35"> <img :src="list.block_947_36"  class="dd"/></a>
                </div>
                <div class="bar2 wfl">
                       <h2><img :src="list.block_947_37"  /></h2>
                </div>
              </div>
          </div>

          <div class="zhongjian wfl"  v-for="list in item.zhongjian" :key="list">
               <div class="z-thumb wfl">
                  <img :src="list.zhongjian_1" />
                  <img :src="list.zhongjian_2" />
                  <img :src="list.zhongjian_3" />
                  <img :src="list.zhongjian_4" />
                  <img :src="list.zhongjian_5" />
               </div>
          </div>



          <div class="block_1314" v-for="list in item.block_1314" :key="list">
                 <div class="m3 wfl">
                      <div class="bg_img">                         
                          <img :src="list.block_1314_1" class="g1"/>
                          <img :src="list.block_1314_2" />
                          <img :src="list.block_1314_3" />
                          <img :src="list.block_1314_4" />
                          <img :src="list.block_1314_5" />
                      </div>
                          <span>{{ list.block_1314_6 }}</span>
                         <em>{{ list.block_1314_7 }}</em>
                       <ul>
                          <li>
                              <h4>{{ list.block_1314_8 }}</h4>
                      <div class="zuo">
                        <img :src="list.block_1314_9" />
                        <i>{{ list.block_1314_10 }}</i>
                      </div>
                      <div class="you">
                          <p>{{ list.block_1314_11 }}<b>{{ list.block_1314_12 }}</b>{{ list.block_1314_121 }}</p>
                      </div>
                      <div class="tupian wfl">
                          <img :src="list.block_1314_13" />
                          <img :src="list.block_1314_14" />
                          <img :src="list.block_1314_15" />
                          <img :src="list.block_1314_16" />
                          <img :src="list.block_1314_17" />
                          <img :src="list.block_1314_18" />
                           </div>
                      </li>
                      <li class="li_2">
                          <h4>{{ list.block_1314_19 }}</h4>
                        <div class="zuo">
                              <img :src="list.block_1314_20" />
                              <i>{{ list.block_1314_21 }}</i>
                        </div>
                       <div class="you">
                              <p>{{ list.block_1314_22 }}<b>{{ list.block_1314_23 }}</b>{{ list.block_1314_24 }}</p>
                       </div>
                       <div class="tupian wfl">
                          <img :src="list.block_1314_25" />
                          <img :src="list.block_1314_26" />
                          <img :src="list.block_1314_27" />
                          <img :src="list.block_1314_28" />
                          <img :src="list.block_1314_29" />
                          <img :src="list.block_1314_30" />
                       </div>
                      </li>
                      </ul>
                     <div class="link3">
                         <a :href="'tel:' + list.block_1314_31"> <img :src="list.block_1314_32"  /></a>
                        <a :href="list.block_1314_33"> <img :src="list.block_1314_34"  class="dd"/></a>
                     </div>
                  </div>
              <div class="bar3 wfl">
                     <h2><img :src="list.block_1314_35"   ></h2>
             </div>
        </div>



        <div class="gg_block wfl" v-for="list in item.gg_block" :key="list">
              <div class="gg_img">
                  <img :src="list.gg_block_1" />
                  <img :src="list.gg_block_2" />
                  <img :src="list.gg_block_3" />
                  <img :src="list.gg_block_4" />
                  <img :src="list.gg_block_5" />
              </div>
              <div class="block_1167">
                   <div class="m4 wfl">
                       <div class="m4_title">
                            <img :src="list.gg_block_6" />
                       </div>
                   <span>
     
                       <img :src="list.gg_block_7" />
                        <img :src="list.gg_block_8" />
                        <img :src="list.gg_block_9" />
                       <img :src="list.gg_block_10" />
                        <img :src="list.gg_block_11" />
                       <img :src="list.gg_block_12" />
                      <img :src="list.gg_block_13" />
                        <img :src="list.gg_block_14" />
                        <img :src="list.gg_block_15" />
                        <img :src="list.gg_block_16" />
                        <img :src="list.gg_block_17" />
                       <img :src="list.gg_block_18" />
                       <img :src="list.gg_block_19" />
                       <img :src="list.gg_block_20" />
                       <img :src="list.gg_block_21" />
                        <img :src="list.gg_block_22" />
                      <img :src="list.gg_block_23" />
                        <img :src="list.gg_block_24" />
                        <img :src="list.gg_block_25" />
                        <img :src="list.gg_block_26" />
                   </span>
                  </div>
              </div>
         </div>




         <div class="smsb_block wfl"    v-for="list in item.smsb_block" :key="list">
               <div class="gg_img">
                     <img :src="list.smsb_block_1" />
                     <img :src="list.smsb_block_2" />
                     <img :src="list.smsb_block_3" />
                     <img :src="list.smsb_block_4" />
                     <img :src="list.smsb_block_5" />
 </div>
 <div class="block_1032">
 <div class="shamiao">
 <div class="sb_bai">
 <div class="sb_title">
 <i>{{ list.smsb_block_6 }}</i>
 <p>{{ list.smsb_block_7 }}</p>
 </div>
 <div class="sb_img wfl">
<img :src="list.smsb_block_8" />
 <img :src="list.smsb_block_9" />
  <img :src="list.smsb_block_10" />
  <img :src="list.smsb_block_11" />
  <img :src="list.smsb_block_12" />
 </div>
 </div>
 </div>
 </div>
 <div class="block_1167">
 <div class="sbxu wfl">
 <img :src="list.smsb_block_13" class="web"/>
 <img :src="list.smsb_block_14" class="web"/>
 <img :src="list.smsb_block_15" class="web"/>
 <img :src="list.smsb_block_16" class="web"/>
 <img :src="list.smsb_block_17" class="web"/>
 <img :src="list.smsb_block_18" class="wap"/>
<img :src="list.smsb_block_19" class="wap"/>
  <img :src="list.smsb_block_20" class="wap"/>
  <img :src="list.smsb_block_21" class="wap"/>
  <img :src="list.smsb_block_22" class="wap"/>
  <img :src="list.smsb_block_23" class="wap"/>
  <img :src="list.smsb_block_24" class="wap"/>
  <img :src="list.smsb_block_25" class="wap"/>
 <img :src="list.smsb_block_26" class="wap"/>
  <img :src="list.smsb_block_27" class="wap"/>
 </div>
 </div>
 <div class="block_1167">
 <div class="xiaoxu_block wfl">
 <div class="block_1032">
 <div class="xiaoxu">
 <div class="xu_bai">
 <span>
 <p> {{ list.smsb_block_28 }}<br>
 {{ list.smsb_block_29 }}</p>
 </span>
 <div class="yisheng_wap">
  <img :src="list.smsb_block_30" />
 </div>
 </div>
 </div>
 </div>
 <div class="yisheng">
   <img :src="list.smsb_block_30" />
 </div>
 </div>
 </div>
               </div>




<div class="bootm_block wfl"    v-for="list in item.bootm_block" :key="list">      
 <div class="b_bar wfl">
 </div>
 <div class="block_1167">
 <div class="sbzhuanjia_img wfl">
  <img :src="list.bootm_block_1" class="web"/>
 <img :src="list.bootm_block_2" class="web"/>
 <img :src="list.bootm_block_3" class="web"/>
  <img :src="list.bootm_block_4" class="web"/>
 <img :src="list.bootm_block_5" class="web"/>
   <img :src="bootm_block_6" class="wap"/>
    <img :src="list.bootm_block_7" class="wap"/>
    <img :src="list.bootm_block_8" class="wap"/>
   <img :src="list.bootm_block_9" class="wap"/>
    <img :src="list.bootm_block_10" class="wap"/>
 </div>
 </div>
 <div class="block_1032">
 <div class="diabu wfl">
 <div class="bb_bai">
 <div class="hui wfl">
 <img :src="list.bootm_block_11" />
 <a :href="list.bootm_block_12" class="j"> 京东店铺</a>
  <a :href="'tel:' + list.bootm_block_13" class="t">  {{ list.bootm_block_13 }}</a>
 </div>
 <div class="bb_bar">
  <img :src="list.bootm_block_14"  />
 </div>
 

 </div>
 </div>
 <div class="diabu_wap">
 <div class="bb_bai_wap">
 <img :src="list.bootm_block_15"  />
  <a :href="list.bootm_block_12" class="j"> 京东店铺</a>
 <div class="bb_bar">
   <img :src="list.bootm_block_14"  />
 </div>
  <a :href="'tel:' + list.bootm_block_13" class="t">  {{ list.bootm_block_13 }}</a>
 <span><img :src="list.bootm_block_16"  /></span>
 <p>{{ list.bootm_block_17 }}<b><span class="zaax-wxh">{{ list.bootm_block_18 }}</span></b></p>
 </div>
 </div>
 </div>
  </div>



  <div class="foot-div"  v-for="list in item.foot" :key="list">  
     <div class="main">
      <div class="wx-ico"> <img :src="list.foot_1" /></div>
       <div class="text">{{ list.bootm_foot_2 }}<br> <span id="aa" class="zaax-wxh">{{ list.foot_3 }}</span></div>
      <a class="foot-btn" onclick="copyArt()">{{ list.foot_4 }}</a>
       </div>
  </div>


</div> 
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tuiguang: null,
    }
  },
  mounted() {
    this.add();
  },
  methods: {
    async add() {
      axios.get('https://api.bf839.com/ad3.json').then((response) => {
        if (response) {
          this.tuiguang = response.data.data.tuiguang;
          console.log('数据获取成功');
        }
        else (
          alert('数据获取失败')
        )
      }
      )
    }
  },

}
</script>

 